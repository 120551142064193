import React from 'react';
import './Header.css';
import '../Button/Button.jsx'
import Button from '../Button/Button.jsx';

const Header = () => {

    return (

        <div class="Header">
        <p1>TRN AUTO 57</p1>
        <h2>МАГАЗИН АВТОЗАПЧАСТЕЙ</h2>
        <h3>+79319997057</h3>
        <div>
        <Button imageurl="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/800px-WhatsApp.svg.png" />
        <Button imageurl="https://m.avito.ru/icons/touch-icon-512x512.png" url='https://www.avito.ru/user/3a00ca838c3803f823595ee6078ac794/profile?src=sharing'/>
        <Button imageurl="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5c/Telegram_Messenger.png/600px-Telegram_Messenger.png" />
        </div>
        </div>
    );
}

export default Header;