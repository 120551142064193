import React from 'react';
import './Footer.css';

const Footer = () => {

    return (

        <div className="Footer">
           
           
            
            
        </div>
    );
}

export default Footer;