import React from 'react';
import './ProductCard.css';


const ProductCard = ({product}) => {
<input className="input-photo" type="file" id={product.id} name="avatar" accept="image/png, image/jpeg" />
                    
    return (
            
        <body>
            <div class="cont">
                
                <div class="product-card">
                    <div class="product-card__image">
                        
                            <img src="https://matoural.ru/thumb/2/ALtGapVB-8pcGOVe5NkvHg/r/d/dvigatel_f16d3_novyj_135_tr.jpg" alt="Red Nike Shoes" />
                            <input class="input-photo" type="file" id={product.id} name="avatar" accept="image/png, image/jpeg" />
                    </div>
                    
                    <div class="product-card__info">
                        <h2 class="product-card__title">{product.title}</h2>
                        <p class="product-card__description">{product.description}</p>
                        <div class="product-card__price-row">
                            <span class="product-card__price">Цена: <b>{product.price}</b></span>
                            <button class="product-card__btn">Купить</button>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </body>
    );
}

export default ProductCard;
