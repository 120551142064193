import React from 'react';
import './Button.css';


const Button = ( props ) => {
    

    return ( 
        <button className="round" >
            <a href={props.url}>
            <img src={props.imageurl} width="30" height="30" alt="Поиск" />
            </a>
        </button>
    );

};

export default Button;